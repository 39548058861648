(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-transition-group"), require("animejs"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-transition-group", "animejs"], factory);
	else if(typeof exports === 'object')
		exports["useRedux"] = factory(require("react"), require("react-transition-group"), require("animejs"));
	else
		root["useRedux"] = factory(root["React"], root["react-transition-group"], root["animejs"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__) {
return 